<template>
  <div style="text-align: left;">
    <div>
      <h1 style="color: black;"> Terms and Conditions of Service</h1>
      <p>Last updated: November 29, 2022</p>
      <p>Please read these terms and conditions carefully before using Our Service.</p>
      <h1 style="color: black;"> Interpretation and Definitions</h1>
      <h2 style="color: black;"> Interpretation</h2>
      <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The
        following definitions shall have the same meaning regardless of whether they appear in singular or in
        plural.</p>
      <h2 style="color: black;"> Definitions</h2>
      <p>For the purposes of these Terms and Conditions:</p>
      <ul>
        <li>
          <p>Affiliate means an entity that controls, is controlled by or is under common control with a party, where
            &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities
            entitled to vote for election of directors or other managing authority.</p>
        </li>
        <li>
          <p>Device means any device that can access the Service such as a computer, a cell phone or a digital
            tablet.</p>
        </li>
        <li>
          <p>ESUCC (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to Educational Service Unit Coordinating Council (ESUCC), 6949 S 110th St, La Vista,
            NE 68128.</p>
        </li>
        <li>
          <p>Service refers to the Website.</p>
        </li>
        <li>
          <p>Terms and Conditions (also referred as &quot;Terms&quot;) mean these Terms and Conditions that form the
            entire agreement between You and the ESUCC regarding the use of the Service. This Terms and Conditions
            agreement has been created with the help of the <a
                href="https://www.termsfeed.com/terms-conditions-generator/">TermsFeed Terms and Conditions
              Generator</a>.</p>
        </li>
        <li>
          <p>Third-party Social Media Service means any services or content (including data, information, products or
            services) provided by a third-party that may be displayed, included or made available by the Service.</p>
        </li>
        <li>
          <p>Website refers to Educational Service Unit Coordinating Council (ESUCC), accessible from <a
              href="https://www.esucc.org/">https://www.esucc.org/</a></p>
        </li>
        <li>
          <p>You means the individual accessing or using the Service, or the ESUCC, or other legal entity on behalf of
            which such individual is accessing or using the Service, as applicable.</p>
        </li>
      </ul>
      <h1 style="color: black;"> Acknowledgment</h1>
      <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between
        You and the ESUCC. These Terms and Conditions set out the rights and obligations of all users regarding the use
        of the Service.</p>
      <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and
        Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the
        Service.</p>
      <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any
        part of these Terms and Conditions then You may not access the Service.</p>
      <p>You represent that you are over the age of 18. The ESUCC does not permit those under 18 to use the Service.</p>
      <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy
        Policy of the ESUCC. Our Privacy Policy describes Our policies and procedures on the collection, use and
        disclosure of Your personal information when You use the Application or the Website and tells You about Your
        privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
        By using our Service, you acknowledge your understanding of and agreement with our Privacy Policy and these
        Terms of Service.</p>
      <h1 style="color: black;"> Links to Other Websites</h1>
      <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the
        ESUCC.</p>
      <p>The ESUCC has no control over, and assumes no responsibility for, the content, privacy policies, or practices
        of any third party web sites or services. You further acknowledge and agree that the ESUCC shall not be
        responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
        connection with the use of or reliance on any such content, goods or services available on or through any such
        web sites or services.</p>
      <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or
        services that You visit.</p>
      <h1 style="color: black;"> Termination</h1>
      <p>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason
        whatsoever, including without limitation if You breach these Terms and Conditions.</p>
      <p>Upon termination, Your right to use the Service will cease immediately.</p>
      <h1 style="color: black;"> Limitation of Liability</h1>
      <p>Notwithstanding any damages that You might incur, the entire liability of the ESUCC and any of its suppliers
        under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the
        amount actually paid by You through the Service.&nbsp; If You have not paid any amount to the ESUCC, then, to
        the maximum extent permitted by law, you may not recover any monetary amount from the ESUCC.</p>
      <p>To the maximum extent permitted by applicable law, in no event shall the ESUCC or its suppliers be liable for
        any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages
        for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of
        privacy arising out of or in any way related to the use of or inability to use the Service, third-party software
        and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms),
        even if the ESUCC or any supplier has been advised of the possibility of such damages and even if the remedy
        fails of its essential purpose.</p>
      <h1 style="color: black;"> &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</h1>
      <p>The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects
        without warranty of any kind. To the maximum extent permitted under applicable law, the ESUCC, on its own behalf
        and on behalf of its Affiliates and its and their respective licensors and service providers, expressly
        disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service,
        including all implied warranties of merchantability, fitness for a particular purpose, title and
        non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade
        practice. Without limitation to the foregoing, the ESUCC provides no warranty or undertaking, and makes no
        representation of any kind that the Service will meet Your requirements, achieve any intended results, be
        compatible or work with any other software, applications, systems or services, operate without interruption,
        meet any performance or reliability standards or be error free or that any errors or defects can or will be
        corrected.</p>
      <p>Without limiting the foregoing, neither the ESUCC nor any of the ESUCC's provider makes any representation or
        warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the
        information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or
        error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through
        the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the ESUCC
        are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
      <h1 style="color: black;"> Governing Law</h1>
      <p>The laws of the State of Nebraska, United States, excluding its conflicts of law rules, shall govern this Terms
        and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or
        international laws.</p>
      <h1 style="color: black;"> Disputes Resolution</h1>
      <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally
        by contacting the ESUCC.</p>
      <h1 style="color: black;"> United States Legal Compliance</h1>
      <p>You represent and warrant that (i) You are not located in a country that is subject to a United States
        government embargo, or that has been designated by the United States government as a &quot;terrorist supporting&quot;
        country, and (ii) You are not listed on any United States government list of prohibited or restricted
        parties.</p>
      <h1 style="color: black;"> Severability and Waiver</h1>
      <h2 style="color: black;"> Severability</h2>
      <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
        interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law
        and the remaining provisions will continue in full force and effect.</p>
      <h2 style="color: black;"> Waiver</h2>
      <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under
        these Terms shall not effect a party's ability to exercise such right or require such performance at any time
        thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</p>
      <h1 style="color: black;"> Changes to These Terms and Conditions</h1>
      <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is
        material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking
        effect. What constitutes a material change will be determined at Our sole discretion.</p>
      <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the
        revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the
        Service.</p>
      <h1 style="color: black;"> Contact Us</h1>
      <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
      <ul>
        <li>
          <p>By email: info@esucc.org</p>
        </li>
      </ul>
      <br/>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfService"
}
</script>

<style scoped>
h1, h2 {
  color: black;
}
</style>